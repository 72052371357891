import React, { useEffect, useRef, useState } from 'react';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import {
  InputDate,
  InputMaskCep,
  InputMaskTelefone,
  InputNumber,
  InputSelect,
  InputText,
  InputTextArea,
  InputUf,
  InputCidade,
  InputMaskRg,
} from '~/components/NovosInputs';
import Separator from '~/components/Separator';
import { useManutencaoFiscal } from '../../ManutencaoFiscalContext';
import { CustomSeparator } from '../../components/CustomSeparator';
import { maskDocumento } from '../../function/maskDocumento';
import { ContainerScreens } from './styles';

export const Documento: React.FC = () => {
  const {
    register,
    errors,
    control,
    setValue,
    watch,
    especie,
    searchCep,
    flgProdutor,
    setInitEspecie,
    initEspecie,
    setInitEstado,
    initEstado,
    loadingIcon,
    conferida,
    cidade,
    setCidade,
  } = useManutencaoFiscal();

  const inputCidadeRef = useRef<HTMLDivElement | null>(null);
  const tipo_pessoa = watch('tipo_pessoa');

  useEffect(() => {
    if (inputCidadeRef.current) {
      const inputCidade: HTMLInputElement | null =
        inputCidadeRef.current.querySelector('[name="cidades"]');

      if (inputCidade && cidade) {
        const { label } = cidade;
        const { value } = inputCidade;
        if (value !== label) {
          if (inputCidade.value) {
            inputCidade.value = label;
          } else {
            inputCidade.value = '';
          }
        }
      }
    }
  }, [cidade]);

  const [currentTab, setCurrentTab] = useState('parceiro');

  return (
    <ContainerScreens>
      <Row>
        <Col md={2} sm={12} style={{ marginTop: '10px' }}>
          <InputDate
            label="Emissão"
            placeholder="Informe a data de emissao"
            name="dtaEmissao"
            control={control}
            register={register}
            isError={!!errors.dtaEmissao}
            disabled={conferida}
          />
        </Col>
        <Col md={2} sm={12} style={{ marginTop: '10px' }}>
          <InputDate
            label="Entrada"
            placeholder="Informe a data de entrada"
            name="dtaEntrada"
            control={control}
            register={register}
            isError={!!errors.dtaEntrada}
            maxLength={0}
            disabled={conferida}
          />
        </Col>
        <Col md={2} sm={12} style={{ marginTop: '10px' }}>
          <InputText
            style={{ paddingRight: '12px' }}
            alignRight
            label="Série"
            min={0}
            step={1}
            placeholder="0"
            name="numSerieNF"
            caseInput="upper"
            disabled={conferida}
            register={register}
            toLowerCase={false}
            isError={!!errors.numSerieNF}
            maxLength={8}
            control={control}
          />
        </Col>
        <Col md={2} sm={12} style={{ marginTop: '10px' }}>
          <InputSelect
            label="Espécie"
            maxLength={50}
            placeholder="Selecione..."
            ListaWidth="500px"
            name="especie"
            disabled={conferida}
            register={register}
            options={especie}
            isError={initEspecie && !!errors.especie}
            control={control}
            changeSelected={(selected: any) => {
              setInitEspecie(false);
              setValue('especie', selected);
            }}
          />
        </Col>
        <Col md={2} sm={12} style={{ marginTop: '10px' }}>
          <InputNumber
            label="Nº NF"
            min={0}
            max={999}
            placeholder="0"
            name="num_nf"
            disabled={conferida}
            register={register}
            isError={!!errors.num_nf}
            maxLength={999}
          />
        </Col>
        <Col md={2} sm={12} style={{ marginTop: '10px' }}>
          <InputNumber
            label="Total NF"
            min={0}
            max={999}
            placeholder="0"
            name="total_nf"
            disabled
            register={register}
            isError={!!errors.total_nf}
            maxLength={999999}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={12} style={{ marginTop: '10px' }}>
          <InputText
            label="Natureza"
            min={0}
            step={1}
            placeholder="Informe a natureza"
            name="natureza"
            disabled={conferida}
            register={register}
            toLowerCase={false}
            isError={!!errors.natureza}
            maxLength={100}
          />
        </Col>
        <Col md={6} sm={12} style={{ marginTop: '10px' }}>
          <InputText
            label="Chave NFe"
            min={0}
            step={1}
            placeholder="Informe a Chave NFe"
            name="chave_nfe"
            disabled={conferida}
            register={register}
            isError={!!errors.chave_nfe}
            maxLength={100}
          />
        </Col>
      </Row>
      <Row>
        <div className="row capa-tabs">
          <div className="col-12" style={{ paddingRight: '0px' }}>
            <CustomSeparator label="" eventKey="1" onChange={() => null}>
              <button
                type="button"
                onClick={() => setCurrentTab('parceiro')}
                className={currentTab === 'parceiro' ? 'active' : ''}
              >
                Dados do Parceiro
              </button>

              {flgProdutor && (
                <button
                  type="button"
                  onClick={() => setCurrentTab('produtor')}
                  className={currentTab === 'produtor' ? 'active' : ''}
                >
                  Produtor
                </button>
              )}
            </CustomSeparator>
          </div>
        </div>
        <div className="data-comparison">
          <Tabs
            id="controlled-NF"
            activeKey={currentTab}
            onSelect={(tab) => setCurrentTab(tab || 'parceiro')}
          >
            <Tab eventKey="parceiro" title="Documento">
              <Row>
                <Col md={12} sm={12} style={{ marginTop: '10px' }}>
                  <InputText
                    label="Razão Social"
                    min={0}
                    step={1}
                    placeholder="Informe a Razão Social"
                    name="razao_social"
                    disabled={conferida}
                    register={register}
                    toLowerCase={false}
                    isError={!!errors.razao_social}
                    maxLength={50}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4} sm={12} style={{ marginTop: '10px' }}>
                  <InputText
                    label="CNPJ/CPF"
                    min={0}
                    step={1}
                    placeholder="Informe a CNPJ/CPF"
                    name="num_documento"
                    disabled={conferida}
                    register={register}
                    isError={!!errors.num_documento}
                    maxLength={18}
                    onChange={(e: any) => {
                      const documentoMask = maskDocumento(e.target.value);
                      setValue('num_documento', documentoMask);
                    }}
                  />
                </Col>
                <Col md={4} sm={12} style={{ marginTop: '10px' }}>
                  {tipo_pessoa === 0 ? (
                    <InputMaskRg
                      label="RG"
                      name="ie_rg"
                      isError={!!errors.ie_rg}
                      control={control}
                    />
                  ) : (
                    <InputText
                      label="IE/RG"
                      min={0}
                      step={1}
                      placeholder="Informe o IE/RG"
                      name="ie_rg"
                      disabled={conferida}
                      register={register}
                      isError={!!errors.ie_rg}
                      maxLength={200}
                    />
                  )}
                </Col>
                <Col md={4} sm={12} style={{ marginTop: '10px' }}>
                  {!conferida && (
                    <InputMaskTelefone
                      label="Telefone"
                      control={control}
                      name="telefone"
                      isError={!!errors.telefone}
                    />
                  )}
                  {conferida && (
                    <InputText
                      label="Telefone"
                      min={0}
                      step={1}
                      placeholder="(__) ____-____"
                      name="telefone"
                      disabled={conferida}
                      register={register}
                      isError={!!errors.telefone}
                      maxLength={200}
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={3} sm={12} style={{ marginTop: '10px' }}>
                  {!conferida && (
                    <InputMaskCep
                      label="CEP"
                      name="cep"
                      control={control}
                      isError={!!errors.cep}
                      hasButton
                      clickedButton={searchCep}
                      loadingIcon={loadingIcon === 'cep'}
                    />
                  )}

                  {conferida && (
                    <InputText
                      label="CEP"
                      min={0}
                      step={1}
                      placeholder="00000-000"
                      name="cep"
                      disabled={conferida}
                      register={register}
                      isError={!!errors.cep}
                      maxLength={9}
                    />
                  )}
                </Col>
                <Col md={6} sm={12} style={{ marginTop: '10px' }}>
                  <InputText
                    label="Endereço"
                    min={0}
                    step={1}
                    placeholder="Informe o Endereço"
                    name="endereco"
                    disabled={conferida}
                    toLowerCase={false}
                    register={register}
                    isError={!!errors.endereco}
                    maxLength={200}
                  />
                </Col>
                <Col md={3} sm={12} style={{ marginTop: '10px' }}>
                  <InputText
                    label="Número"
                    min={0}
                    step={1}
                    placeholder="Informe o Número"
                    name="numero"
                    disabled={conferida}
                    register={register}
                    isError={!!errors.numero}
                    maxLength={200}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4} sm={12} style={{ marginTop: '10px' }}>
                  <InputText
                    label="Bairro"
                    min={0}
                    step={1}
                    placeholder="Informe o Bairro"
                    name="bairro"
                    disabled={conferida}
                    toLowerCase={false}
                    register={register}
                    isError={!!errors.bairro}
                    maxLength={200}
                  />
                </Col>
                <Col
                  md={3}
                  sm={12}
                  style={{ marginTop: '10px' }}
                  ref={inputCidadeRef}
                >
                  <InputCidade
                    maxLength={29}
                    setValue={setValue}
                    label="Cidade"
                    placeholder="Selecione a Cidade"
                    name="cidades"
                    cidadeABuscar={cidade}
                    register={register}
                    clearCampo={false}
                    isError={!!errors.cidades}
                    control={control}
                    disabled={conferida}
                    changeSelected={(selected: any) => {
                      setValue('cidades', selected);
                      setCidade(selected);
                    }}
                  />
                </Col>
                <Col md={2} sm={12} style={{ marginTop: '10px' }}>
                  <InputText
                    label="Cód. Município"
                    min={0}
                    step={1}
                    placeholder="0"
                    name="cod_municipio"
                    disabled={conferida}
                    register={register}
                    isError={!!errors.cod_municipio}
                    maxLength={200}
                  />
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="produtor" title="Fiscal">
              <Row>
                <Col md={2} sm={12} style={{ marginTop: '10px' }}>
                  <InputText
                    style={{ paddingRight: '15px' }}
                    alignRight
                    label="Série"
                    min={0}
                    step={1}
                    placeholder="0"
                    name="num_serie_produtor"
                    caseInput="upper"
                    disabled={conferida}
                    register={register}
                    control={control}
                    isError={!!errors.num_serie_produtor}
                    maxLength={8}
                  />
                </Col>
                <Col md={2} sm={12} style={{ marginTop: '10px' }}>
                  <InputText
                    label="Espécie"
                    min={0}
                    step={1}
                    placeholder="Informe a Espécie"
                    name="des_especie_produtor"
                    disabled={conferida}
                    toLowerCase={false}
                    register={register}
                    isError={!!errors.cod_municipio}
                    maxLength={200}
                  />
                </Col>
                <Col md={2} sm={12} style={{ marginTop: '10px' }}>
                  <InputNumber
                    label="Nº NF:"
                    min={0}
                    max={99999999999}
                    placeholder="0"
                    name="num_nf_produtor"
                    disabled={conferida}
                    register={register}
                    isError={!!errors.cod_municipio}
                    maxLength={200}
                  />
                </Col>
                <Col md={2} sm={12} style={{ marginTop: '10px' }}>
                  <InputDate
                    label="Emissão"
                    min={0}
                    step={1}
                    placeholder="Informe o código do Municipio"
                    name="dta_emissao_produtor"
                    disabled={conferida}
                    control={control}
                    register={register}
                    isError={!!errors.cod_municipio}
                    maxLength={200}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4} sm={12} style={{ marginTop: '10px' }}>
                  <InputText
                    label="CNPJ/CPF"
                    min={0}
                    step={1}
                    placeholder="Informe o CNPJ/CPF"
                    name="num_cpf_cnpj_produtor"
                    disabled={conferida}
                    register={register}
                    isError={!!errors.num_cpf_cnpj_produtor}
                    maxLength={18}
                    onChange={(e: any) => {
                      const documentoMask = maskDocumento(e.target.value);
                      setValue('num_cpf_cnpj_produtor', documentoMask);
                    }}
                  />
                </Col>
                <Col md={3} sm={12} style={{ marginTop: '10px' }}>
                  <InputText
                    label="IE/RG"
                    min={0}
                    step={1}
                    placeholder="Informe o IE/RG"
                    name="num_rg_ie_produtor"
                    disabled={conferida}
                    register={register}
                    isError={!!errors.cod_municipio}
                    maxLength={200}
                  />
                </Col>
                <Col md={2} sm={12} style={{ marginTop: '10px' }}>
                  <InputUf
                    maxLength={2}
                    setValue={setValue}
                    placeholder="Selecione"
                    name="estado_produtor"
                    register={register}
                    isError={!!errors.estado_produtor}
                    control={control}
                    changeSelected={(selected) => {
                      setValue('estado_produtor', selected);
                    }}
                    isDisabled={conferida}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12} sm={12} style={{ marginTop: '10px' }}>
                  <InputText
                    label="Chave NFe:"
                    min={0}
                    step={1}
                    placeholder="Informe a Chave NFe"
                    name="num_chave_produtor"
                    disabled={conferida}
                    register={register}
                    isError={!!errors.cod_municipio}
                    maxLength={200}
                  />
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </div>
      </Row>

      <Separator labelText="Observação" />
      <Row>
        <Col md={12} sm={12} style={{ marginTop: '-13px' }}>
          <InputTextArea
            label=""
            placeholder=""
            name="observacao"
            disabled={conferida}
            register={register}
            control={control}
            isError={!!errors.observacao}
            maxLength={500}
          />
        </Col>
      </Row>
    </ContainerScreens>
  );
};
