import * as yup from 'yup';
import { validar } from '~/utils/ie';


const cpfRegex = /^\d{3}\.?\d{3}\.?\d{3}-?\d{2}$/;
const cnpjRegex = /^\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2}$/;
const rgRegex = /^\d{1,2}\.?\d{3}\.?\d{3}-?[0-9Xx]$/;

// caso a nf  seja conferida
const createConditionalSchema = (fieldSchema: any) =>
  fieldSchema.when('flg_conferida', {
    is: (value: any) => value,
    then: fieldSchema.notRequired().nullable(),
    otherwise: fieldSchema.required(),
  });

export const schema = yup
  .object({
    dtaEmissao: createConditionalSchema(yup.string()),
    dtaEntrada: createConditionalSchema(yup.string()),
    numSerieNF: createConditionalSchema(yup.string()),
    num_nf: createConditionalSchema(yup.number()),
    total_nf: createConditionalSchema(yup.number()),
    natureza: createConditionalSchema(yup.string()),

    razao_social: createConditionalSchema(yup.string()),
    // num_documento: createConditionalSchema(yup.string()),
    num_documento: yup
      .string()
      .required()
      .test((value: any, context) => {
        const { parent } = context;
        const tipoPessoa = parent.tipo_pessoa;

        if (tipoPessoa === 0) {
          return cpfRegex.test(value);
        }
        if (tipoPessoa === 1) {
          return cnpjRegex.test(value);
        }
        return false;
      }),
    ie_rg: yup
      .string()
      .when('tipo_pessoa', {
        is: 1,
        then: (schemaIE) =>
          schemaIE.required('Campo obrigatório')
            .test(function (value) {
              const estados = this.parent?.cidades.ufValue;
              if (!value) return false;

              if (!estados) {
                return true;
              }
              if (estados) {
                if (!estados) return true;
                return validar(value, estados);
              }

              return false;
            }),
        otherwise: (schemaRG) =>
          schemaRG
            .notRequired()
            .test((value) => {

              if (!value) return true;
              const rgvalue = value.replace(/\D/g, '');
              return rgRegex.test(rgvalue);
            }),
      }),
    telefone: createConditionalSchema(yup.string()),
    cep: createConditionalSchema(yup.string()),
    numero: createConditionalSchema(yup.string()),
    endereco: createConditionalSchema(yup.string()),
    // cidade: createConditionalSchema(yup.string()),
    bairro: createConditionalSchema(yup.string()),
    especie: yup.object().when('flg_conferida', {
      is: (value: any) => {
        if (value === 0) return true;
        return false;
      },
      then: yup.object().shape({
        label: yup.string().required(),
        value: yup.number().required(),
      }),
      otherwise: yup.object().notRequired().nullable(true),
    }),
    estado: yup.object().when('flg_conferida', {
      is: (value: any) => {
        if (value === 0) return true;
        return false;
      },
      then: yup.object().shape({
        label: yup.string().required(),
        value: yup.number().required(),
      }),
      otherwise: yup.object().notRequired().nullable(true),
    }),
  })
  .required();

export const schemaModal = yup
  .object({
    cod_gtin: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    cfop: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    num_cest: yup
      .string()
      .required()
      .test((value) => {
        // Remove os caracteres especiais e verifica possui 7 digitos
        value = value?.replace(/\D/g, '');
        return value?.length === 7;
      }),
    descricao: yup.string().required(),
    tributacao: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    num_ncm: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    des_embalagem: yup.string().required(),
    qtd_embalagem: yup.number().required(),
    qtd_entrada: yup.number().required(),
    qtd_total: yup.number().required(),
    val_total: yup.string().required(),
    val_desconto: yup.string().required(),
    acrescimo: yup.string().required(),
    val_bc_icms: yup.string().required(),
    val_icms_op: yup.string().required(),
    val_outros: yup.string().required(),
    val_isento: yup.string().required(),
    val_bc_st: yup.string().required(),
    st: yup.string().required(),
  })
  .required();
